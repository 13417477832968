import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import Lightbox from 'react-image-lightbox';

class ElementorGallery extends Component {
  constructor(props) {
    super(props);

    const { sources, srcUrl } = props;

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
    this.images = [];

    this.children = this.props.children[0].props.children
      .filter(({ type }) => type !== 'br')
      .map((node, i) => {
        const anchor = node.props.children[0].props.children[0];
        const img = anchor.props.children[0];
        const {
          props: { fluid, alt },
        } = img;
        const {
          props: { to },
        } = anchor;
        const { key } = anchor;
        const {
          source: { publicURL },
        } = sources[`${srcUrl}${to}`];
        this.images.push(publicURL);

        return (
          <div key={key} role="button" onClick={() => this.setState({ isOpen: true, photoIndex: i })}>
            <Image fluid={fluid} alt={alt} />
          </div>
        );
      });

    this.gallery = React.createRef();
  }

  prevImage = () => {
    const { photoIndex } = this.state;

    this.setState({
      photoIndex: (photoIndex + this.images.length - 1) % this.images.length,
    });
  };

  nextImage = () => {
    const { photoIndex } = this.state;

    this.setState({
      photoIndex: (photoIndex + 1) % this.images.length,
    });
  };

  closeGallery = () => {
    this.setState({
      photoIndex: 0,
      isOpen: false,
    });
  };

  render() {
    const { isOpen, photoIndex } = this.state;
    const { children, className: wrapperClassName } = this.props;
    const {
      props: { className },
    } = children[0];

    return (
      <>
        <div ref={this.gallery} className={wrapperClassName}>
          <div className={className}>{this.children}</div>
        </div>
        {isOpen && (
          <Lightbox
            mainSrc={this.images[photoIndex]}
            nextSrc={this.images[(photoIndex + 1) % this.images.length]}
            prevSrc={this.images[(photoIndex + this.images.length - 1) % this.images.length]}
            images={this.images}
            onMoveNextRequest={this.nextImage}
            onMovePrevRequest={this.prevImage}
            onCloseRequest={this.closeGallery}
          />
        )}
      </>
    );
  }
}

ElementorGallery.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ElementorGallery;
