import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { FaTimes } from 'react-icons/fa';

import classNames from '../helper/class-names';
import usePortal from '../hook/portal';
import useOverlay from '../hook/overlay';

const zIndex = { current: 999 };

const WrapperModal = ({ show, className, onClose, closeOnOuterClick, children }) => {
  const target = usePortal('modal');
  const __modal = useRef(null);

  const hideOnOuterClick = (e) => {
    e.stopPropagation();
    return closeOnOuterClick && e.target.dataset.modal && onClose(e);
  };

  useOverlay(show);

  if (!show) {
    return null;
  }

  return createPortal(
    <div
      className={classNames('modal', className)}
      onClick={hideOnOuterClick}
      data-modal="true"
      style={{ zIndex: zIndex.current++ }}
    >
      <div className="modal__container" ref={__modal}>
        {children}
        <button type="button" className="modal__close" onClick={onClose}>
          <i>
            <FaTimes />
          </i>
        </button>
      </div>
    </div>,
    target
  );
};

WrapperModal.propTypes = {
  show: PropTypes.bool,
  close: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  closeOnOuterClick: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
};

WrapperModal.defaultProps = {
  show: false,
  closeOnOuterClick: true,
  onClose: () => {},
  close: true,
  className: '',
  children: null,
  loading: false,
};

export default WrapperModal;
