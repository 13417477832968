import React from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';

const onDragStart = (e) => e.preventDefault();

const Figure = ({ fluid, alt, className, width, height }) => {
  const style = { maxWidth: `${width}px`, width: '100%', maxHeight: `${height}px`, margin: 'auto', overflow: 'unset' };
  const size = (className || '').replace(/^.*size-/gi, '');

  switch (size) {
    case 'medium':
      const current = Math.max(width, height);
      style.maxWidth = `${current}px`;
      style.maxHeight = `${current}px`;
      style.width = `${current}px`;
      style.height = `${current}px`;
      break;
    case 'fullscreen':
      style.width = '100%';
      style.maxWidth = '100%';
      delete style.maxHeight;
      break;
    case 'dynamic':
      style.width = '100%';
      style.maxWidth = '100%';
      style.maxHeight = '100vh';
      break;
    case 'extra-medium':
      style.maxWidth = '640px';
      style.maxHeight = '640px';
      break;
    default:
  }

  return <Image onDragStart={onDragStart} className={className} alt={alt} fluid={fluid} style={style} />;
};

Figure.defaultProps = {
  alt: '',
};

Figure.propTypes = {
  fluid: PropTypes.shape({}).isRequired,
  alt: PropTypes.string,
  className: PropTypes.string.isRequired,
};

export default Figure;
