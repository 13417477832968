import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import classNames from '../../../helper/class-names';
import FieldError from './error';

const events = ['change', 'keydown', 'keyup', 'focus', 'blur'];

const FieldText = ({ elastic, input, required, label }) => {
  let timeout = null;
  const inputRef = useRef(null);

  const resizeStage = () => {
    const { current: inputDom } = inputRef;
    if (!inputDom.scrollHeight) {
      return;
    }

    if (timeout !== null) {
      return;
    }

    timeout = setTimeout(() => {
      const { value, style } = inputDom;

      if (!value) {
        style.height = '3rem';
      } else {
        style.height = `${inputDom.scrollHeight / 2}px`;
        style.height = `${inputDom.scrollHeight + 2}px`;
      }

      timeout = null;
    }, 16);
  };

  useEffect(() => {
    const { current: inputDom } = inputRef;
    if (!elastic || !inputDom) {
      return null;
    }

    events.forEach((e) => inputDom.addEventListener(e, resizeStage));
    inputDom.style.overflow = 'hidden';
    resizeStage();

    return () => {
      clearTimeout(timeout);
      events.forEach((e) => inputDom.removeEventListener(e, resizeStage));
    };
  }, [elastic, timeout]);

  return (
    <div
      className={classNames('form__field', required && 'form__field--required', input.value && 'form__field--active')}
    >
      <FieldError name={input.name} />
      <textarea
        {...input}
        id={input.name}
        className="forminputDom"
        placeholder={label}
        required={required}
        ref={inputRef}
      />
      {(label && (
        <label htmlFor={input.name} className="form__label">
          {label}
        </label>
      )) ||
        null}
    </div>
  );
};

FieldText.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
  }).isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  elastic: PropTypes.bool,
};

FieldText.defaultProps = {
  elastic: true,
  required: false,
  label: '',
};

export default FieldText;
