import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const BlockSuccess = ({ title, message }) => {
  const block = useRef(null);

  useEffect(() => {
    const { current } = block;
    if (current) {
      setTimeout(
        () => current && [current.parentNode.scrollIntoView(true), window.scrollBy && window.scrollBy(0, -144)],
        0
      );
    }
  }, []);

  return (
    <div ref={block} className="alert__box alert__box--green text-center" role="alert">
      <div className="checkmark checkmark--green" />
      <p className="font-bold mb-6">{title}</p>
      {message && <p className="font-light">{message}</p>}
    </div>
  );
};

BlockSuccess.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
};

BlockSuccess.defaultProps = {
  message: '',
};

export default BlockSuccess;
