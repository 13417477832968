import React, { useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import WrapperModal from '../../wrapper/modal';
import { padZero } from '../../helper/date';

const NewsBlock = ({ title, content, date }) => {
  const nDate = new Date(date);
  const [isOpen, setOpen] = useState(false);
  const sDate = `${padZero(nDate.getDate())}.${padZero(nDate.getMonth() + 1)}.${nDate.getFullYear()}`;

  const toggleModal = () => setOpen(!isOpen);

  return (
    <li>
      <button type="button" onClick={toggleModal}>
        <small>{sDate}</small>
        <span>{title}</span>
      </button>
      <WrapperModal show={isOpen} onClose={toggleModal}>
        <ul>
          <li className="modal__title">
            <small>{sDate}</small>
            {title}
          </li>
          <li dangerouslySetInnerHTML={{ __html: content }} />
        </ul>
      </WrapperModal>
    </li>
  );
};

const ElementorNews = () => (
  <StaticQuery
    query={graphql`
      query getNews {
        wpgraphql {
          posts(where: { stati: [PUBLISH, FUTURE] }, last: 5) {
            nodes {
              id
              title
              content
              date
              status
            }
          }
        }
      }
    `}
    render={({
      wpgraphql: {
        posts: { nodes },
      },
    }) =>
      nodes.length > 0 && (
        <ul className="news__box">
          {nodes
            .filter(({ status }) => status === 'publish')
            .map((news) => (
              <NewsBlock key={news.id} {...news} />
            ))}
        </ul>
      )
    }
  />
);

export default ElementorNews;
