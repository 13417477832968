import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class ElementorScript extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);
    this.instance = React.createRef();
    this.timeout = null;
    this.script = null;
  }

  componentDidMount() {
    const {
      children: [src],
    } = this.props;
    const { current: instance } = this.instance;
    this.script = document.createElement('script');
    this.script.innerHTML = src;
    this.timeout = setTimeout(() => instance.appendChild(this.script), 0);
  }

  componentWillUnmount() {
    const { current: instance } = this.instance;
    clearTimeout(this.timeout);
    instance.removeChild(this.script);
  }

  render() {
    return <div ref={this.instance} />;
  }
}

export default ElementorScript;
