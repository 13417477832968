import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const Slide = ({ content }) => (
  <div
    style={{
      height: '100%',
      width: '100%',
      backgroundImage: `url(${content})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    }}
  />
);

Slide.propTypes = {
  content: PropTypes.string.isRequired,
};

const SliderContent = ({ translate, transition, size, children, type }) => {
  let styles = {
    transition: `transform ${transition}ms ease-out`,
    display: 'flex',
  };

  switch (type) {
    case 'slide_right':
    case 'slide_left':
      styles.transform = `translateX(-${translate}px)`;
      styles.height = '100%';
      styles.width = `${size}px`;
      break;
    case 'slide_up':
    case 'slide_down':
      styles.transform = `translateY(-${translate}px)`;
      styles.height = '100%';
      styles.width = `100%`;
      styles.flexWrap = 'wrap';
      break;

    default:
  }

  return <div style={styles}>{children}</div>;
};

SliderContent.propTypes = {
  children: PropTypes.node.isRequired,
  translate: PropTypes.number.isRequired,
  transition: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['fade', 'slide_right', 'slide_left', 'slide_up', 'slide_down']).isRequired,
};

const Slider = ({ slides, transition, duration, type }) => {
  const getSize = () => {
    switch (type) {
      case 'slide_down':
      case 'slide_up':
        return window.innerHeight;
      case 'slide_right':
      case 'slide_left':
        return window.innerWidth;
      default:
        return 0;
    }
  };

  const ping = useRef(null);
  const [lastIndex, setLastIndex] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [translate, setTranslate] = useState(0);

  const nextSlide = () => {
    const nextIndex = activeIndex + 1;
    if (activeIndex === slides.length - 1) {
      return [setActiveIndex(0), setTranslate(0)];
    }

    return [setActiveIndex(nextIndex), setTranslate(nextIndex * getSize())];
  };

  const prevSlide = () => {
    const nextIndex = activeIndex - 1;
    const maxIndex = slides.length - 1;

    if (activeIndex === 0) {
      return [setActiveIndex(maxIndex), setTranslate(maxIndex * getSize())];
    }

    return [setActiveIndex(nextIndex), setTranslate(nextIndex * getSize())];
  };

  const slide = () => {
    switch (type) {
      case 'slide_down':
      case 'slide_right':
        prevSlide();
        break;
      case 'slide_up':
      case 'slide_left':
        nextSlide();
        break;
      default:
    }
  };

  useEffect(() => {
    ping.current = setTimeout(slide, 2000);

    return () => clearTimeout(ping.current);
  }, [activeIndex]);

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        margin: '0 auto',
        overflow: 'hidden',
      }}
    >
      <SliderContent translate={translate} transition={transition} size={getSize() * slides.length} type={type}>
        {slides.map(({ id, url }) => (
          <Slide key={id} content={url} />
        ))}
      </SliderContent>
    </div>
  );
};

Slider.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  duration: PropTypes.number.isRequired,
  transition: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['fade', 'slide_right', 'slide_left', 'slide_up', 'slide_down']).isRequired,
};

const ElementorSectionSlideShow = ({ className, settings, children, ...props }) => {
  const config = JSON.parse(settings);
  const type = 'slide_up'; // config.background_slideshow_slide_transition;

  return (
    <section className={className} {...props}>
      <Slider
        slides={config.background_slideshow_gallery}
        transition={config.background_slideshow_transition_duration}
        duration={config.background_slideshow_slide_duration}
        type={type}
      />
      {children}
    </section>
  );
};

ElementorSectionSlideShow.propTypes = {
  className: PropTypes.string.isRequired,
  settings: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ElementorSectionSlideShow;
