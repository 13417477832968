import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';

import unified from 'unified';
import parser from 'rehype-parse';
import rehype2react from 'rehype-react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Teaser from '../components/teaser';
import Slider from '../components/slider';
import Figure from '../components/figure';

import ElementorTabs from '../components/elementor/tabs';
import ElementorSlider from '../components/elementor/slider';
import ElementorToggle from '../components/elementor/toggle';
import ElementorScript from '../components/elementor/script';
import ElementorGallery from '../components/elementor/gallery';
import ElementorCounter from '../components/elementor/counter';
import ElementorContact from '../components/elementor/contact';
import ElementorProgress from '../components/elementor/progress';
import ElementorNews from '../components/elementor/news';
import ElementorSectionSlideShow from '../components/elementor/section-slideshow';

const ExternalLink = ({ href, children, ...props }) => (
  // eslint-disable-next-line
  <a target="_blank" rel="noopener noreferrer" href={href} {...props}>
    {children}
  </a>
);

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const EXTERNAL_CHECK = /^(https?:\/\/|mailto:|tel:)/;

const getProcessor = ({ images, sources, srcUrl }) =>
  unified()
    .use(parser, {
      fragment: true,
    })
    .use(rehype2react, {
      createElement,
      components: {
        a: ({ href, ...props }) =>
          href && href.indexOf(process.env.GATSBY_SITE_URL) !== 0 && EXTERNAL_CHECK.test(href)
            ? createElement(ExternalLink, { href, ...props })
            : createElement(Link, {
                // replace last slash in link and url sate
                to: String(href).replace(/\/$/, '').replace(process.env.GATSBY_SITE_URL, ''),
                ...props,
              }),
        script: (props) => createElement(ElementorScript, props),
        img: (rest) => {
          const img = Object.keys(images).find((key) => key.includes(rest.src));
          return images[img]
            ? createElement(Figure, {
                fluid: images[img],
                ...rest,
              })
            : null;
        },
        span: ({ className, ...props }) => {
          switch (className) {
            case 'elementor-counter-number':
              return createElement(ElementorCounter, { className, ...props });
            default:
              return createElement('span', { className, ...props });
          }
        },
        div: ({ className, ...props }) => {
          switch (className) {
            case 'elementor-news':
              return createElement(ElementorNews, { className });
            case 'elementor-contact':
              return createElement(ElementorContact, { className, ...props });
            case 'elementor-toggle':
            case 'elementor-accordion':
              return createElement(ElementorToggle, { className, ...props });
            case 'elementor-image-gallery':
              return createElement(ElementorGallery, {
                className,
                images,
                sources,
                srcUrl,
                ...props,
              });
            case 'elementor-tabs':
              return createElement(ElementorTabs, { className, ...props });
            case 'elementor-progress-bar':
              return createElement(ElementorProgress, { className, ...props });
            default:
              return String(className).includes('elementor-widget-image-carousel')
                ? createElement(ElementorSlider, {
                    className,
                    ...props,
                    sources,
                  })
                : createElement('div', { className, ...props });
          }
        },
        section: ({ className, 'data-settings': dataSettings, ...props }) => {
          if (String(className).includes('elementor-element') && dataSettings && dataSettings.includes('slideshow')) {
            return <ElementorSectionSlideShow className={className} settings={dataSettings} {...props} />;
          }

          return createElement('section', { className, 'data-settings': dataSettings, ...props });
        },
      },
    });

const IndexPage = ({
  data: {
    wpgraphql: {
      pageBy: {
        title,
        content,
        elementorImages: images,
        elementorSources: sources,
        featuredImage: media,
        tags,
        acfSeo,
      },
      generalSettings: { url: srcUrl },
    },
  },
}) => {
  const imgMapping = (images || []).reduce(
    (
      list,
      {
        url,
        image: {
          childImageSharp: { fluid },
        },
      }
    ) => ({
      ...list,
      [url]: fluid,
    }),
    {}
  );

  const srcMapping = (sources || []).reduce(
    (list, { url, alt, source }) => ({
      ...list,
      [url]: {
        url,
        alt,
        source,
      },
    }),
    {}
  );

  const body =
    content &&
    getProcessor({
      images: imgMapping,
      sources: srcMapping,
      srcUrl,
    }).processSync(
      String(content)
        .replace(new RegExp(srcUrl, 'g'), '')
        .replace(/<!--[^\\[].+-->/g, '')
        .replace(/[\r\n\t]+/g, ' ')
        .replace(/>[\s]+</g, '><')
        .replace(/[\s]+/g, ' ')
        .replace(/({.*?[^{]})/g, (a) => a.replace(/"/g, '@'))
    ).result;

  return (
    <Layout>
      <SEO
        title={(acfSeo && acfSeo.title) || title}
        description={acfSeo && acfSeo.description}
        keywords={(tags.edges && tags.edges.map(({ name }) => name)) || []}
      />
      {media && media.length > 0 && !acfSeo.slider && (
        <Teaser
          alt={media[0].altText}
          title={media[0].title || title}
          fluid={media[0].imageFile.childImageSharp.fluid}
        />
      )}
      {acfSeo.slider && <Slider slides={acfSeo.slider} />}
      <main>
        {!media && <h1 className="main__title container" dangerouslySetInnerHTML={{ __html: title }} />}
        <div className="main__content">{body}</div>
      </main>
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export const pageQuery = graphql`
  query pageQuery($pageId: Int) {
    wpgraphql {
      generalSettings {
        url
      }
      pageBy(pageId: $pageId) {
        id
        content
        elementorImages {
          url
          image {
            childImageSharp {
              fluid(maxWidth: 1024) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        elementorSources {
          url
          alt
          source {
            publicURL
            id
          }
        }
        elementorData
        slug
        status
        link
        title
        pageId
        menuOrder
        tags {
          edges {
            node {
              name
              slug
              id
              link
            }
          }
        }
        acfSeo {
          title
          description
          slider {
            id
            title
            altText
            description
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1920, maxHeight: 1080) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        featuredImage {
          node {
            id
            title
            altText
            sourceUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1920, maxHeight: 1080) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
